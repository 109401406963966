module.exports = {
  languages: [
    {
      code: 'en',
      name: 'English',
      nativeName: 'English',
      short: 'EN',
      manifest: {
        name: 'duotec',
        short_name: 'duotec',
        description: 'Building tommorow’s sensor and connectivity platforms',
      },
      routes: [{ key: 'articles', name: 'news' }],
    },
    {
      code: 'de',
      name: 'German',
      nativeName: 'Deutsch',
      short: 'DE',
      manifest: {
        name: 'duotec',
        short_name: 'duotec',
        description: 'Zukunftsweisende Sensor- und Konnektivitätsplattformen',
      },
      routes: [{ key: 'articles', name: 'news' }],
    },
    {
      code: 'fr',
      name: 'French',
      nativeName: 'Français',
      short: 'FR',
      manifest: {
        name: 'duotec',
        short_name: 'duotec',
        description:
          'Construire les plateformes de capteurs et de connectivité de demain',
      },
      routes: [{ key: 'articles', name: 'news' }],
    },
    {
      code: 'es',
      name: 'Spanish',
      nativeName: 'Español',
      short: 'ES',
      manifest: {
        name: 'duotec',
        short_name: 'duotec',
        description:
          'Construyendo las plataformas de conectividad y sensores de mañana',
      },
      routes: [{ key: 'articles', name: 'news' }],
    },
  ],
  defaultLanguage: 'de',
  fallbackLanguage: 'en',
};
