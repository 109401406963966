var smoothscroll = require('smoothscroll-polyfill');
const { LANGUAGE_KEY } = require('gatsby-plugin-react-i18next/dist/types');
const { languages, defaultLanguage } = require('./locales/languages');

exports.onClientEntry = () => {
  smoothscroll.polyfill();

  if (typeof window !== 'undefined') {
    let detected = window.localStorage.getItem(LANGUAGE_KEY);

    if (!detected) {
      const userLanguage = (
        (navigator.language || navigator.userLanguage) ??
        ''
      )
        .substr(0, 2)
        .toLowerCase();

      if (languages.map((l) => l.code).includes(userLanguage)) {
        detected = userLanguage;
      }
    }

    if (
      detected &&
      detected !== defaultLanguage &&
      window.location.pathname === '/'
    ) {
      window.location.replace(`/${detected}/`);
    }
  }
};
